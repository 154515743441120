html {
}

.PortalTopContainer {
  width: 100%;
  height: 260px;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(./Assets/web-bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 113px;
}

.WebTopContainer {
  width: 100%;
  height: 260px;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(./Assets/Projects/TopBackImg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 113px;
}

.PortalTopTitle {
  cursor: default;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 64px;
  text-align: center;
  letter-spacing: 0.01em;
  background-image: linear-gradient(to right,  #F4A628,  #FD7C2A, #c9662d,#ffe601, #15e6a7, #00E2C8, #F4A628,  #FD7C2A, #c9662d,#ffe601 );
  color :transparent; 
  background-clip:text;
  margin-bottom: 30px;
}

.PortalSubTitle {
  cursor: default;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 64px;
  text-align: center;
  letter-spacing: 0.01em;
  background-image: linear-gradient(to right,  #F4A628,  #FD7C2A, #c9662d,#ffe601, #00E2C8, #F4A628,  #FD7C2A, #c9662d,#ffe601 );
  color :transparent; 
  background-clip:text;
  margin: 30px 0;
}

.NormalText {
  text-align: justify;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.ShiningText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.01em;
  background-image: linear-gradient(to right,  #F4A628, #c9662d,#ffe601 );
  color :transparent; 
  background-clip:text;
}

button:focus {
  box-shadow: none !important;
}
.transformable {
    will-change: transform !important;
    transition: all 400ms cubic-bezier(0.03, 0.98, 0.52, 0.99) 0s !important;
    transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1) !important;
}

    .transformable:hover {
        transform: perspective(1000px) rotateX(0deg) rotateY(30deg) scale3d(1, 1, 1) !important;
    }
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #c1d5d5;
  background-image: url(./Assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
  color: #ffffff;
  padding-top: 180px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
    overflow-x: hidden !important;
    overflow-y: auto;
    height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
    width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #000d0d;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #0ba1a4;
    border-radius: 2px;
}

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #ffffff;
        border-radius: 12px;
    }

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
    background-color: rgba(3, 16, 30,  0.686) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.02rem 2rem !important;
  font-size: 1.2rem !important;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

    .navbar-toggler span {
        display: block !important;
        background-color: #00CED1 !important;
        height: 4px !important;
        width: 27px !important;
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        transform: rotate(0deg) !important;
        left: 0 !important;
        opacity: 1 !important;
    }

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #13232b !important;
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}
.navbar-brand {
  color: rgb(255, 255, 255) !important;
}

.logo {
  height: 1.4em !important;
  width: 2.5em !important;
}

.navbar-nav .nav-link {
  color: white !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
    content: "";
    position: relative;
    display: block;
    height: 3px;
    width: 0;
    border-radius: 16px;
    background: #ffffff;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item-active a::after {
  content: "";
  position: relative;
  display: block;
  height: 3px;
  width: 0;
  border-radius: 16px;
  background: #ffffff;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

.navbar-nav .nav-item-active a::after {
  width: 100%;
}

/* --------- */
/* Home section */
/* --------- */
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

.home-header {
  padding-top: 80px !important;
}

.home-section {
    position: relative;
    background-position: top center;
    background-repeat: no-repeat;
    padding-bottom: 0px !important;
    padding-top: 20px !important;
}

.home-content {
    padding: 4rem 0 0rem !important;
    text-align: left;
}

.heading {
  font-size: 2.4em !important;
  padding-left: 50px !important;
}

.heading-name {
  font-size: 2.5em !important;
  padding-left: 45px !important;
}

.ListContainer {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.itemContainer {
  overflow: hidden;
  width: 945px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.itemContainer .title:nth-child(2n) {
  animation: slideFromRight 1s linear 0s;
}
.itemContainer .title:nth-child(2n + 1) {
  animation: slideFromLeft 1s linear 0s;
}
.mainTitle {
  font-weight: 600;
  font-size: 28px;
  color: #1A1A1A;
  height: auto;
}
.subTitle {
  width: 500px;
  font-weight: 400;
  font-size: 18px;
  color: #6B6B6B;
}
.subTitle img {
  height: 14px;
  width: 14px;
  margin-right: 20px;
}
.web3ImgContainer {
  height: 300px;
}
.web3ImgContainer img {
  height: 339px;
  width: 433px;
  background-size: cover;
  animation: appearAndEnlarge 1s ease-out 0s;
  transition: all 0.5s ease 0s !important;
}
.web3ImgContainer img:hover {
  transform: scale(1.1);
}
.web3ImgContainer2 img {
  height: 339px;
  width: 462px;
  background-size: cover;
  animation: appearAndEnlarge 1s ease-out 0s;
  transition: all 0.5s ease 0s !important;
}
.web3ImgContainer2 img:hover {
  transform: scale(1.1);
}

.shake-word {
  display: inline-block;
  animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) infinite;
  transform: translate3d(0, 0, 0);
}

@keyframes appearAndEnlarge {
  from {
      opacity: 0;
      transform: scale(0.1)
  }
  to {
      opacity: 1;
      transform: scale(1)
  }
}


.Typewriter__wrapper {
    font-size: 2.2em !important;
    color: #00CED1 !important;
    font-weight: 600 !important;
}
.Typewriter__cursor {
    font-size: 2.4em !important;
    color: #00CED1 !important;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;
    position: absolute !important;
  }
  .Typewriter__cursor {
    display: none !important;
  }
}

.home-about-section {
    position: relative;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}

.home-about-description {
  color: white !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.home-about-body {
  padding-top: 50px;
  font-size: 1.2em !important;
  text-align: left;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: white !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #68187a;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #801f95;
}

.home-social-icons:hover {
  color: #87209e;
  box-shadow: 0 0 5px #87209e;
  text-shadow: 0 0 2px #87209e;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-colour {
  color: #700c86 !important;
}

/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: rgb(10, 4, 22);
  bottom: 0 !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important ;
}
.footer-copywright {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-body {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: #a588c0 !important;
}
/* --------- */
/* Projects */
/* --------- */
.project-section {
  position: relative !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
}

.project-card {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.project-card-view {
    box-shadow: 0 3px 5px 1px #08575a !important;
    border: 1.7px solid rgba(60, 188, 216, 0.64) !important;
    border-radius: 10px !important;
    color: white !important;
    background-color: transparent !important;
    opacity: 0.9 !important;
    transition: all 0.5s ease 0s !important;
    height: 100% !important;
}
.project-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 2px 10px 0px #136f72 !important;
}

.card-img-top {
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top:10px !important;
    padding-bottom: 0px !important;
    opacity: 1 !important;
    border-radius: 10px !important;
}


.btn-primary {
  color: #fff !important;
  background-color: #0ba1a4 !important;
  border-color: #0ba1a4 !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #00CED1  !important;
  border-color: #00CED1  !important;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.project-heading {
  color: white !important;
  font-size: 2.3em !important;
  font-weight: 500 !important;
  padding-top: 20px !important;
}

/* --------- */
/* About */
/* --------- */
.video-outline {
    border-radius: 10px !important;
    background-color: transparent !important;
    animation: slide 1s linear 0s !important;
    transition: all 0.5s ease 0s !important;
}

.video-outline:hover {
    transform: scale(1.03) !important;
    overflow: hidden !important;
}

.about-section {
  position: relative !important;
  padding-top: 110px !important;
  padding-bottom: 30px !important;
  padding-right:20px !important;
  padding-left:20px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}
.tech-icons {
    font-size: 2em !important;
    margin-top:15px !important;
    margin-right:15px !important;
    margin-left:15px !important;
    padding: 2px !important;
    padding-bottom: 5px !important;
    opacity: 0.93 !important;
    border: 1.7px solid rgb(28 194 220 / 0.73) !important;
    vertical-align: middle !important;
    text-align: center !important;
    border-radius: 5px !important;
    display: table !important;
    box-shadow: 4px 5px 4px 3px rgb(4 62 168 / 0.14) !important;
    overflow: hidden !important;
    transition: all 0.4s ease 0s !important;
}
.tech-icons:hover {
  transform: scale(1.1) !important;
  overflow: hidden !important;
  box-shadow: 0 2px 10px 0px #136f72 !important;
}

@media (max-width: 767px) {
    .tech-icons {
        margin: 10px !important;
    }
}

.tech-icon-images {
    padding: 20px !important;
    line-height: 1.6 !important;
}

.quote-card-view {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}
/* --------- */
/* Resume */
/* --------- */

.resume-section {
  position: relative !important;
  padding-top: 120px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.resume {
  padding-top: 20px;
  padding-bottom: 50px;
  justify-content: center;
}

.resume-left {
  padding-right: 80px !important;
}

.resume-right {
  padding-left: 80px !important;
}

@media (max-width: 767px) {
  .resume-left {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .resume-right {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
.resume .resume-title {
  font-size: 2em;
  font-weight: 700;
  padding-top: 30px;
  padding-bottom: 30px;
}

.resume .resume-item {
  padding: 0 0 10px 25px;
  margin-top: -2px;
  border-left: 2px solid #22cbb4;
  position: relative;
}

.resume .resume-item .resume-title {
  line-height: 18px;
  font-size: 0.9em;
  background: #344979;
  padding: 8px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.resume .resume-item ul {
  padding-left: 20px;
  text-align: justify;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
  list-style: none;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #8a49a8;
}

@keyframes slide {
  0% {
    opacity: 0;
    transform: translateY(400px);
  }
  20% {
    opacity: 0.2;
    transform: translateY(320px);
  }
  40% {
    opacity: 0.4;
    transform: translateY(240px);
  }
  60% {
    opacity: 0.6;
    transform: translateY(160px);
  }
  80% {
    opacity: 0.8;
    transform: translateY(80px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  
  }
}

@keyframes slideFromRight {
  from {
    opacity: 0;
    transform: translateX(200px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes slideFromLeft {
  from {
    opacity: 0;
    transform: translateX(-200px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  
  }
  
}

.fork-btn {
  font-size: 1.1em !important;
  padding-top: 10px !important;
}

.fork-btn-inner {
  line-height: 1.4em !important;
  background-color: #934cce5e !important;
  padding: 0.25rem 1.1rem !important;
  vertical-align: middle !important;
  text-align: center !important;
}

.fork-btn-inner:hover {
  transform: translateY(-2px) !important;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
}
.fork-btn-inner::after {
  display: none !important;
}

.icon-path {
  animation: icon-path-animation 8s ease-in infinite;
}