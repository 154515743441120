.sectionWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.sectionTitle{
    background-image: radial-gradient(#A829FE, #FD7C2A, #00E2C8, #F4A628);
    color :transparent; 
    background-clip:text;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 13px;
}

.sectionMainTitle{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #1A1A1A;
    margin-bottom: 35px;

}
.sectionDesc{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #6B6B6B;
    margin-bottom: 60px;
    width: 733px;
    text-align:center;
}

.imgContainer {
    margin: 0 auto;
    width: 1200px;
    /* height: 300px; */
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    align-items: center;
}

.imgCellContainer {
    width: 400px;
    height: 430px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #6b6b6b1b;
    position: relative;
    animation: enlarge 1s ease-in-out 1;
}

.upTab {
    position: absolute;
    height: 150px;
    width: 100%;
    /* background: #F1F1F1; */
    top: 0;
    /* color: #1A1A1A; */
    padding: 30px;
    text-align: left;
}

.downTab {
    position: absolute;
    height: 150px;
    width: 100%;
    background: #1A1A1A;
    bottom: 0;
    color: #FFFFFF;
    padding: 30px;
    text-align: left;
}

.tabTitle{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;   
    margin-bottom: 9px;
}

.tabText{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
}

@keyframes slidein {
    from {
      top: 100%;
      height: 0;

    }
  
    to {
      top: 0;
      height: 100%;
    }
}

@keyframes enlarge {
    from {
        opacity: 0;
        transform: scale(0.1);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes slideinReverse {
    from {
      /* top: 100%; */
      height: 0;

    }
  
    to {
      /* top: 0; */
      height: 100%;
    }
}

.dark-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    color: #FFFFFF;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
    top: 0;
    animation-duration: 1s;
    animation-name: slideinReverse;
}

.light-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
    top: 0;
    animation-duration: 1s;
    color: #1A1A1A;
    animation-name: slideinReverse;
}

.innerContentWrapper{
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 30px 30px 0;
    text-align: left;
}

.contentIndex {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 9px;
}

.contentTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 8px;
}

.contentText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 12px;
}

.contentLink {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    cursor: pointer;
}

.bigImageContainer{
    width: 100%;
    height: 100%;
    padding-top: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bigImageTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #1A1A1A;
    margin-bottom: 48px;

}

.CarouselContainer{

    height: 507px;
    width: 900px;
}

.CarouselContainer .ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover {
  left: 10px;
  z-index: 2;
  color: white;
  font-size: 20px;
  height: 30px;
}

.CarouselContainer .ant-carousel .slick-next,
.ant-carousel .slick-next:hover {
  right: 10px;
  z-index: 2;
  color: white;
  font-size: 20px;
  height: 30px;
}